<template>
  <div class="animated">
    <b-card>
      <h5 class="smalltitle">Audit logs</h5>
      <div slot="header">
        <i class="icon-list"></i> Weblogs
      </div>
      <v-client-table
        :columns="columns"
        :data="data"
        :options="options"
        :theme="theme"
        id="dataTable"
        style="width: 100%"
      ></v-client-table>
    </b-card>
  </div>
</template>

<script>
import Vue from "vue";
import { ClientTable, Event } from "vue-tables-2";

Vue.use(ClientTable);

export default {
  name: "DataTable",
  components: {
    ClientTable,
    Event
  },
  data: function() {
    return {
      columns: ["executed", "user_id", "username", "action"],
      data: [],
      options: {
        filterByColumn: true,
        columnsDisplay: {
          executed: "not_mobile",
          user_id: "not_mobile"
        },
        texts: {
          filter: "Filter",
          filterBy: "Filter by {column}"
        },
        sortable: ["executed", "user_id", "username", "action"],
        filterable: ["user_id", "username", "action"],
        sortIcon: {
          base: "fa",
          up: "fa-sort-asc",
          down: "fa-sort-desc",
          is: "fa-sort"
        },
        pagination: {
          chunk: 5,
          edge: true,
          nav: "scroll"
        },
        childRow: false,
        highlightMatches: true
      },
      useVuex: false,
      theme: "bootstrap4",
      template: "default"
    };
  },
  beforeRouteUpdate(to, from, next) {
    next();
    this.axios
      .get("/api/v1/servers/" + this.$route.params.guild_id + "/weblogs")
      .then(r => {
        this.data = r.data;
      });
  },
  created: function() {
    this.$Progress.start();
    this.axios
      .get("/api/v1/servers/" + this.$route.params.guild_id + "/weblogs")
      .then(r => {
        this.data = r.data;
        this.$Progress.finish();
      });
  }
};
</script>

<style lang="scss">
#dataTable {
  width: 100%;
  margin: 0 auto;

  .VuePagination {
    text-align: center;
    justify-content: center;
  }

  .vue-title {
    text-align: center;
    margin-bottom: 10px;
  }

  .VueTables__search-field {
    display: flex;
  }
  .VueTables__search-field input {
    margin-left: 0.25rem;
  }

  .VueTables__limit-field {
    display: flex;
  }

  .VueTables__limit-field select {
    margin-left: 0.25rem !important;
  }

  .VueTables__table th {
    text-align: center;
  }

  .VueTables__child-row-toggler {
    width: 16px;
    height: 16px;
    line-height: 16px;
    display: block;
    margin: auto;
    text-align: center;
  }

  .VueTables__child-row-toggler--closed::before {
    content: "+";
  }

  .VueTables__child-row-toggler--open::before {
    content: "-";
  }
}
</style>
